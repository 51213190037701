.weeklyTaskItem, .weeklyTaskItemOrange {
    width: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 8px 3px 9px;
    border-radius: 8px;
    margin: 21px;
    -moz-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    -webkit-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    cursor: pointer;
}

.weeklyTaskItemOrange {
    background-color: var(--restoic-red);
}

.weeklyTaskTitle, .weeklyTaskTitleOrange {
    font: 500 22px "Poppins", sans-serif;
    color: black;
    margin-left: 1rem;
}

.weeklyTaskTitleOrange {
    color: white;
}

.actionIcon {
    width: 38px;
    height: 38px;
    margin: 5px;
}

.groupOfTasksLabel {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    justify-self: flex-start;
    margin: 33px 0;
}

.weeklyTasksContainer {
    background-color: #f8f9fb;
    display: flex;
    flex-direction: column;
}

.weeklyProgressBox {
    margin-top: 1rem;
    padding: 1.25rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 18px 18px rgb(25 31 79 / 13%);
}

.weeklyProgressBoxSubtitle {
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Poppins", sans-serif;
    color: rgb(117,117,117);
}

.weeklyTasksContent {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-top: 215px;
}

.weeklyTasksCardsWrapper {
    width: 70%;
}

.weeklyTasksStatsWrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.weeklyTasksStatsContent {
    border-left: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 89px;
    padding: 0 34px;
}

.weeklyTasksStatsLabel {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 33px;
}

.weeklyTasksStatusProgressLabel,
.weeklyTasksStatusGraphSubtitle,
.weeklyTasksStatusGraphPercentage {
    text-align: center;
    text-transform: uppercase;
    color: black;
}

.weeklyTasksStatusProgressLabel {
    font: 600 18px "Poppins", sans-serif;
    margin-bottom: 5px;
}

.weeklyTasksStatusGraphSubtitle {
    font: 600 10px "Poppins", sans-serif;
    background: var(--restoic-grey);
}

.weeklyTasksStatusGraphPercentage {
    font: 700 28px "Poppins", sans-serif;
    background: var(--restoic-grey);
}

.graphChildren {
    background-color: white;
}

.nextWeekModalTitle {
    font: 600 28px "Poppins", sans-serif;
    color: var(--restoic-black);
    margin-bottom: 14px;
}

.nextWeekModalItem {
    font: normal 22px "Poppins", sans-serif;
    color: black;
    margin-bottom: 35px;
}

.nextWeekModalItemLogo {
    background-image: url("../../assets/images/red_background_logo.png");
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    margin-bottom: 24px;
}

.taskModalTitle {
    font: 600 36px "Poppins", sans-serif;
    text-transform: capitalize;
    color: var(--restoic-black);
    margin-bottom: 40px;
}

.taskModalItem {
    font: normal 18px "Poppins", sans-serif;
    color: black;
}

.taskModalButton, .taskModalButtonComplete {
    width: 212px;
    height: 58px;
    font: bold italic 18px "Poppins", sans-serif;
    text-transform: uppercase;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskModalButton {
    background: var(--restoic-red);
    color: #ffffff;
    cursor: pointer;
}

.taskModalButton:hover {
    filter: brightness(1.2);
}

.taskModalButtonComplete {
    background: var(--restoic-grey);
    color: black;
    margin-top: 25px;
}

.taskModalCategoryInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.taskModalCategoryTitle {
    font: 500 22px "Poppins", sans-serif;
    color: black;
}

.taskModalDownloadIcon {
    width: 50px;
    height: 50px;
    top: 15px;
    left: 20px;
    cursor: pointer;
}

.taskModalDownloadIcon:hover {
    filter: brightness(1.2);
}

.coachCornerContent {
  height: 100px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  line-height: 150%;
  color: black;
  text-align: left;
  overflow: hidden;
}

.coachCornerAction {
  font: 600 18px "Poppins", sans-serif;
  text-align: center;
  background: var(--restoic-red);
  width: 60px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.coachCornerAction:hover {
    filter: brightness(1.2);
}

.coachInsightIcon {
  background-image: url("../../assets/icons/icon_recommended.png");
  width: 22px;
  height: 22px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 12px;
}