.choicePlanWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.choicePlanLogo {
    background-image: url('../../assets/images/SideBar_Logo.png');
    width: 20vw;
    height: 10vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.choicePlanDivider {
    width: 30vw;
    border-radius: 15px;
    transform: skew(-45deg);
    height: 5px;
    background: var(--restoic-red);
    margin-bottom: 36px;
}

.choicePlanTitle {
    font: bold 36px 'Poppins', sans-serif;
    text-align: center;
    color: var(--restoic-black);
    width: 60%;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choicePlanItemsWrapper {
    background-color: rgb(240,241,243);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.choicePlanFooter {
    background-color: rgb(240,241,243);
    width: 100%;
    text-align: center;
}

.choicePlanAlertText {
    margin: 60px 0 6px 0;
    font: italic 18px 'Poppins', sans-serif;
    color: black;
}

.choicePlanAlertButton {
    font: bold 20px 'Poppins', sans-serif;
    text-decoration-line: underline;
    color: var(--restoic-red);
    padding-bottom: 66px;
    cursor: pointer;
}