.downloadItemButtonTitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
}

.downloadItemButton {
    background: #e83a1f;
}

.downloadButtonBox {
    position: absolute;
    left: 2%;
    top: 60%;
    display: flex;
    justify-content: right;
    align-items: center;
    font: 12px normal Poppins, sans-serif;
    text-transform: uppercase;
    color: #e83a1f;
}

.groupOfDownloadsLabel {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.165px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
}

.downloadItemTitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 175.19%;
    color: #030303;
    text-align: left;
    width: 65%;
}
