.mainContainer {
    position: absolute;
    background-image: url("../../assets/images/Login_Background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    /* padding: 136px 0 0 0; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.loginContainer {
    /* overflow: auto; */
    /* display: flex; */
    /* margin: 0 auto; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-top: 45px; */
    /* width: 100%; */
    /* padding-top: 64px; */
    width: 100%;
    overflow-y: auto;
}

.loginContent {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
    /* margin-top: 100px;
    margin-bottom: 100px; */
}

.loginCenter {
    width: 360px;
    background: rgba(0, 0, 0, 0.79);
    backdrop-filter: blur(9px);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 72px 82px 54px 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 67px auto 0 auto;
}

.loginCenterWelcomeText {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #ffffff;
    margin-bottom: 41px;
}

.loginInput {
    width: 327px;
    height: 58px;
    background: #ffffff;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 18px;
    padding-left: 22px;
    outline-width: 0px;
    margin-bottom: 20px;
}

.loginInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

.loginInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
}

.loginInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
}

.loginCenterRememberWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 34px;
}

.loginCenterRememberMe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.loginCenterRememberMeText {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.165px;
    color: #ffffff;
    margin-left: 9px;
}

.rememberMeInput {
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 9px;
}

.loginCenterForgotPassword {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.165px;
    color: #ffffff;
    cursor: pointer;
}

.loginButton {
    position: relative;
    background-color: #e83a1f;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
}

.disabled {
    pointer-events: none;
    background-color: #eb8374;
}

.loadingPopover {
    width: 100vw;
    height: 100vh;
    background: transparent;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    margin: 0;
}

.loginTermsText {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #ffffff;
    margin-top: 35px;
}

.loginTermsLabel {
    color: #e83a1f;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
}

.googleButton {
    width: 100%;
    height: 58px;
    background-color: white;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    margin-top: 21px;
}

.googleButtonIcon {
    background-image: url("../../assets/icons/googleButton.png");
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 18px;
}

.googleButtonText {
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    text-transform: uppercase;
    color: #000000;
}

.appleButton {
    width: 100%;
    height: 58px;
    background-color: black;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    margin-top: 21px;
}

.appleButtonIcon {
    background-image: url("../../assets/icons/appleButton.png");
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 18px;
}

.appleButtonText {
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    text-transform: uppercase;
    color: white;
}

.loginHeaderLogo {
    background-image: url("../../assets/images/Restoic_Logo.png");
    width: 165px;
    height: 51px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
