.navItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  background: var(--restoic-black);
}

.sideBarLogo {
  background-image: url("../../assets/images/SideBar_Logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 255px;
  height: 96px;
  margin: 20px 0 40px 20px;
  cursor: pointer;
  filter: invert(85%);
}

@media only screen and (max-width: 960px) {
  .sideBarLogo {
    background-image: url("../../assets/images/logo.png");
    width: 54px;
    margin: 1rem;
  }
}

.sideBarLogout {
  border-top: 1px solid #f2f5f8;
  margin-top: auto;
  padding-top: 30px;
}

.sideBarItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
}

.sideBarIcon {
  margin-left: 30px;
  transition-duration: 0.2s;
  color: rgb(220,220,220);
}

.sideBarActiveDash {
  background-color: #f5573e;
  width: 5px;
  height: 0;
  border-radius: 5px;
  transition-duration: 0.2s;
}

.sideBarLabel {
  font: 600 18px "Poppins", sans-serif;
  text-transform: uppercase;
  color: rgb(220,220,220);
  transition-duration: 0.2s;
  margin-left: 2rem;
}

.sideBarIconAndLabel {
  display: flex;
  align-items: center;
  width: 100%;
}

.sideBarIconAndLabel:hover {
  background-color: rgb(60,60,60);
}
