.memberStatsUserInfo {
    font: 600 18px "Poppins", sans-serif;
    color: black;
}

.teamProgressMemberFilterTitle {
    font: 600 22px "Poppins", sans-serif;
    color: black;
    text-transform: uppercase;
}

.taskModalButton {
    width: 212px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold italic 18px "Poppins" sans-serif;
    text-transform: uppercase;
    color: white;
    background: #e83a1f;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 30px;
}

.taskModalButton.disabled {
    cursor: auto;
    pointer-events: "none";
    opacity: 0.6;
}
