.choicePlanItemWrapper {
    width: 302px;
    height: 520px;
    background: #ffffff;
    margin: 24px;
    display: flex;
    flex-direction: column;
}

.choicePlanItemNumbOfWeeks, .choicePlanItemNumbOfWeeksLast {
    width: 100%;
    height: 69px;
    background: var(--restoic-black);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}

.choicePlanItemNumbOfWeeks {
    border: 2px solid var(--restoic-black);
}

.choicePlanItemNumbOfWeeksLast {
    border: 7px solid var(--restoic-red);
    border-bottom: 0px;
    height: 80px;
}

.choicePlanItemNumbOfWeeksText {
    font: 600 20px "Poppins", sans-serif;
    color: #ffffff;
}

.choicePlanItemBody, .choicePlanItemBodyLast {
    padding: 0 1rem 1.25rem 1rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.choicePlanItemBody {
    border: 2px solid var(--restoic-black);
    height: 450px;
}

.choicePlanItemBodyLast {
    border: 7px solid;
    border-top: 0;
    border-image-source: linear-gradient(162.88deg, #ff2200 0.67%, #ff9900 98.56%);
    border-image-slice: 1;
    height: 100%;
}

.choicePlanItemTitleText {
    font: 600 1rem "Poppins", sans-serif;
    color: #000000;
    margin: 20px auto;
}

.choicePlanItemTitleTextRecommended {
    font: 600 14px "Poppins", sans-serif;
    line-height: 16px;
    color: #000000;
    text-align: left;
    margin-top: 14px;
    margin-bottom: 20px;
}

.choicePlanItemRecommendedWrapper {
    width: 298px;
    height: 39px;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choicePlanItemRecommendedText {
    font: bold 14px "Poppins", sans-serif;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
}

.choicePlanListItemWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.choicePlanListItemIcon {
    width: 14px;
    height: 12px;
    margin: 2px 21px 0 0;
    color: var(--restoic-red);
}

.choicePlanListItemTitle {
    font: 500 13px "Poppins", sans-serif;
    line-height: 14px;
    color: #000000;
    margin-bottom: 15px;
}

.choicePlanItemButton, .choicePlanItemButtonGradient {
    height: 58px;
    background: var(--restoic-red);
    border-radius: 4px;
    font: bold italic 18px "Poppins", sans-serif;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.choicePlanItemButtonGradient {
    background: linear-gradient(162.88deg, var(--restoic-red) 0.67%, #ff9900 98.56%);
}

.choicePlanItemButton:hover, .choicePlanItemButtonGradient:hover {
    filter: brightness(1.2);
}

.choicePlanItemTriangle {
    width: 0;
    height: 0;
    position: absolute;
    top: -1px;
    right: -1px;
    border-top: 64px solid var(--restoic-red);
    border-left: 64px solid transparent;
}

.choicePlanItemStar {
    position: absolute;
    top: -55px;
    left: -27px;
    color: white;
    font-size: 40px;
}
