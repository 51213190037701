.successMessage {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 21px;
    color: white;
    word-spacing: 2px;
}

.downloadLinkContainer {
    margin-top: auto;
}

.downloadText {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 21px;
}

.downloadIconContainer {
    display: flex;
    margin-top: 20px;
    margin-bottom: 210px;
}

.downloadLinkIcons {
    margin: 25px;
    cursor: pointer;
}

.phoneInput {
    width: 300px;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .redeemContainer {
        width: 360px;
        height: 100%;
        margin: 0 auto;
        margin-top: 150px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
        background-color: #E83A1F;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
    }
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    .redeemContainer {
        width: 360px;
        height: 100%;
        margin: 0 auto;
        margin-top: 150px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
        background-color: #E83A1F;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .redeemContainer {
        width: 360px;
        height: auto;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
      background-color: #E83A1F;
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins', sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.165px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
    }
    .downloadLinkContainer {
        margin-top: 175px;
    }
    .downloadIconContainer {
        margin-bottom: 100px;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .redeemContainer {
        width: 360px;
        height: auto;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
      background-color: #E83A1F;
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins', sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.165px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
        margin-bottom: 30px;
    }
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    .redeemContainer {
        width: 360px;
        height: auto;
        margin: 0 auto;
        margin-top: 75px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
      background-color: #E83A1F;
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins', sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.165px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .downloadLinkIcons {
        margin-left: 15px;
        margin-right: 15px;
    }
    .downloadLinkContainer {
        margin-top: 135px;
    }
    .downloadIconContainer {
        margin-bottom: 60px;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .redeemContainer {
        margin: 0 auto;
        margin-top: 65px;
        margin-bottom: 25px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    
    .redeemButton {
        background-color: transparent;
        width: 100%;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        text-transform: uppercase;
        color: #FFFFFF;
        cursor: pointer;
        border-radius: 4px;
        border: solid 1px white;
        margin-top: 70px;
    }
    
    .redeemButtonActive {
      background-color: #E83A1F;
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins', sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.165px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 70px;
    }
    
    .redeemText {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.165px;
        color: #FFFFFF;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .downloadIconContainer {
        flex-direction: column;
        margin-bottom: 60px;
    }

    .downloadLinkContainer {
        margin-top: 120px;
    }
  }