.courseContainer {
    background-color: #f8f9fb;
    display: flex;
    flex-direction: column;
}

.courseWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.courseContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-self: center;
    background-color: white;
    border-radius: 4px;
    margin-top: 215px;
    -moz-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    -webkit-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
}

.courseProgressWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.courseWeeklyProgress {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.courseOverallProgress {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.courseGraphTitle {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #000000;
}

.courseGraphWrapper {
    width: 350px;
}

.courseGraphSubtitle {
    font: 18px "Poppins", sans-serif;
    text-transform: capitalize;
    color: rgba(33, 34, 43, 0.66);
}

.courseGraphDate {
    font: bold 1rem "Poppins", sans-serif;
    text-transform: uppercase;
    color: var(--restoic-black);
}

.courseGraphPercentage {
    font: 700 28px "Poppins", sans-serif;
    text-align: center;
    color: var(--restoic-black);
    text-transform: uppercase;
}

.courseGraphPercentageSubtitle {
    font: 600 12px "Poppins", sans-serif;
    color: var(--restoic-black);
    text-transform: uppercase;
}

.courseMemberWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 85px;
}

.courseProgressWrapper {
  margin-bottom: 5px;
}

.coursePlayerWrapper {
  margin-top: 30px;
}

.courseMemberFilterTitle {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: var(--restoic-black);
}

.courseDummyDiv {
    width: 100px;
}

.courseFilterWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.courseFilterLabel {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #e83a1f;
}

.courseFilterIcon {
    width: 11px;
    margin-left: 5px;
}

.courseMemberStatsWrapper {
    display: flex;
    flex-direction: column;
}
