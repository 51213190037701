@media (min-width: 1281px) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh !important;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        width: 50%;
        background-image: url("../../assets/images/BackgroundRedeem.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .authRightSide {
        width: 50%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 88px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: none;
    }

    .authRedeemWrapperMobile {
        display: none;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh !important;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        width: 50%;
        background-image: url("../../assets/images/BackgroundRedeem.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .authRightSide {
        width: 50%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 88px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: none;
    }

    .authRedeemWrapperMobile {
        display: none;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        display: none;
    }

    .authRightSide {
        width: 100%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 88px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
        align-self: center;
    }

    .authRedeemWrapperMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 30px;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 50px;
        height: 50px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        display: none;
    }

    .authRightSide {
        width: 100%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 88px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
        align-self: center;
    }

    .authRedeemWrapperMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 30px;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 50px;
        height: 50px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        display: none;
    }

    .authRightSide {
        width: 100%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 88px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
        align-self: center;
    }

    .authRedeemWrapperMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 30px;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 50px;
        height: 50px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
    .authContainer {
        display: flex;
        margin: 0 auto;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(18, 18, 18);
        overflow: hidden;
    }

    .authLeftSide {
        display: none;
    }

    .authRightSide {
        width: 100%;
        overflow-y: scroll;
        background: rgb(18, 18, 18);
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        height: 100vh !important;
        background-image: url("../../assets/images/Auth_right_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .authLogo {
        background-image: url("../../assets/images/Restoic_Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 287.94px;
        height: 89px !important;
        align-self: center;
    }

    .authRedeemWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 116px;
        align-items: center;
    }

    .authRedeemIcon {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;
    }

    .authRedeemText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
        margin-left: 40px;
    }

    .authQestionWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .authQuestionText {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        margin-right: 8px;
    }

    .authQuestionIcon {
        background-image: url("../../assets/icons/icon_questoonMark.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 24px;
        height: 24px;
    }

    .authQestionWrapperMobile {
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;
        align-self: center;
    }

    .authRedeemWrapperMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 30px;
    }

    .authRedeemIconMobile {
        background-image: url("../../assets/icons/icon_giftRedeem.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 50px;
        height: 50px;
    }

    .authRedeemTextMobile {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #ffffff;
        width: 220px;
    }
}
