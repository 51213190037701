.errorInput {
  color: red;
  margin-left: 20px;
  margin-top: -20px;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.defaultInput {
  color: white;
  width: 100%;
  height: 44px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 16px;
  outline-width: 0px;
  margin-bottom: 20px;
  background-color: transparent !important;
  outline: transparent !important;
  border-bottom: 1px solid #e9ebed;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  padding: 0px;
}

.defaultInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  font-weight: bold;
}

.defaultInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.defaultInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
