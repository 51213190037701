body {
  height: 100%;
  background-color: rgb(240,241,243);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 75%);
  z-index: 9999;
}

.contentBox {
  width: 100%;
  padding: 5%;
}

.divider {
  border: 1px solid #F8F9FB;
  width: 100%;
}

#root {
  height: 100%;
}

:root {
  --restoic-red: #e83a1f;
  --restoic-grey: #e9edf5;
  --restoic-black: #21222b;
}

@keyframes enlarge {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.5)
  }
  100% {
    transform: scale(1)
  }
}
