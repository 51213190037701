.reportsContent {
  display: flex;
  margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
  .reportsContent {
    flex-direction: column;
  }
}
