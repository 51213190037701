.memberStatsWrapper {
  display: flex;
  align-items: center;
  min-height: 124px;
  width: 100%;
}

.memberStatsProgressBarWrapper {
  flex-basis: 60%;
  display: flex;
  align-items: center;
}

.memberStatsProgressAllWrapper {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
}

.memberStatsUserImage {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-left: -10px;
}

.memberStatsProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
  width: 100%;
}

.memberStatsUserInfo {
  margin-top: -20px;
  margin-bottom: 3px;
  text-align: left;
  flex-basis: 33%;
  cursor: pointer;
}

.memberStatsUserInfo:hover {
  text-decoration: underline;
}

.memberStatsLabel {
  font: 18px "Poppins", sans-serif;
  text-transform: capitalize;
  color: rgba(33, 34, 43, 0.66);
  text-align: right;
}

.memberStatsPercentage {
  font: bold 18px "Poppins", sans-serif;
  text-transform: capitalize;
  color: var(--restoic-black);
}

.memberStatsGraph {
  background: var(--restoic-grey);
  border-radius: 2px;
  width: 100%;
  height: 13px;
  transform: skew(-45deg);
}

.memberStatsOrangeGraph {
  background: var(--restoic-red);
  border-radius: 2px;
  height: 13px;
}
