.forgotPasswordContainer {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/Login_Background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 65px;
}

.forgotPasswordContent {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.forgotPasswordCenter {
    min-height: 590px;
    width: 360px;
    background: rgba(0, 0, 0, 0.79);
    backdrop-filter: blur(9px);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 72px 82px 54px 82px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPasswordCenterWelcomeText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #FFFFFF;
    margin-bottom: 41px;
}

.inputContainer {
    width: 327px;
    height: 58px;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 18px;
    padding-left: 22px;
    outline-width: 0px;
    margin-bottom: 20px;
}

.inputContainer::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}
  
.inputContainer:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}
  
.inputContainer::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}

.sendEmailButton {
    background-color: #E83A1F;
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 4px;
}

.sendEmailButtonContainer {
    width: 100%;
    align-items: flex-end;
    flex-grow: 1;
    display: flex;
}

.forgotPasswordTermsText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.165px;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 10px;
}

.forgotPasswordTermsLabel {
    color: #E83A1F;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.errorInput {
    color: red;
    margin-left: 20px;
    margin-top: -20px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
  }

.successInputMessage {
  color: rgb(183, 243, 183);
  margin-left: 20px;
  margin-top: -20px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
}
