.athletesContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

@media only screen and (max-width: 1000px) {
  .athletesContent {
    flex-direction: column;
  }
}
