.surveysPrintButtonBox {
  display: flex;
  justify-content: left;
  align-items: center;
  font: 14px normal Poppins, sans-serif;
  text-transform: uppercase;
  margin-left: 10px;
}

.surveysContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}

.surveysBox {
  position: relative;
  background: white;
  border-radius: 5px;
  margin: 15px 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
              0px 1px 1px 0px rgb(0 0 0 / 14%),
              0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.surveysBox::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
  background: var(--restoic-black);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
              0px 1px 1px 0px rgb(0 0 0 / 14%),
              0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.surveysBox::after {
  content: '';
  position: absolute;
  width: 55px;
  height: 55px;
  background: rgb(222,221,212);
  border-radius: 50%;
  top: -18px;
  left: 45%;
  background-image: url("../../../../assets/images/logo.png");
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  filter: invert(100%);
}

.surveysHeader {
  display: flex;
  margin: 40px 40px 0 40px;
}

.surveysQABox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  padding: 5px 0;
  font-weight: bold;
  background: rgb(245,245,245);
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
              0px 1px 1px 0px rgb(0 0 0 / 14%),
              0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.surveysNameDate {
  margin: 10px;
  color: rgb(100,100,100);
  font: bold 16px Poppins, sans-serif;
  font-size: clamp(12px, 2vw, 16px);
  flex-basis: 50%;
  text-align: center;
}

.surveysQuestion {
  margin: 10px;
  color: rgb(100,100,100);
  font: normal 16px Poppins, sans-serif;
  font-size: clamp(12px, 2vw, 16px);
  flex-basis: 50%;
}

.surveysAnswer {
  color: white;
  min-width: 160px;
  max-width: 220px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  margin: 25px;
  border-radius: 4px;
  background: var(--restoic-red);
  font: bold 16px Poppins, sans-serif;
  font-size: clamp(14px, 2vw, 16px);
}

@media only screen and (max-width: 1000px) {
  .surveysQABox {
    flex-direction: column;
  }
}

@media print {
  .css-28ttwv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }

  .surveysContainer {
    overflow: visible !important;
  }

  .surveysContent {
    padding: 0;
  }

  .navItemContainer, .surveysBox::before, .surveysBox::after, .surveysPrintButtonBox {
    display: none;
  }
}

/* Specific property to fix left-margin issue on CHROME PRINT ONLY */
@media print and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    .contentBox {
      margin-left: 100px;
    }
}
