.teamProgressContainer {
    background-color: #f8f9fb;
    display: flex;
    flex-direction: column;
}

.teamProgressWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 56px;
    padding-right: 56px;
}

.teamProgressContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-self: center;
    background-color: white;
    border-radius: 4px;
    margin-top: 215px;
    -moz-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    -webkit-box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    box-shadow: 0 18px 18px rgba(25, 31, 79, 0.13);
    padding: 36px 62px 0px 36px;
    margin-bottom: 30px;
}

.teamProgressProgressWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.teamProgressWeeklyProgress {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.teamProgressOverallProgress {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.teamProgressGraphTitle {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #000000;
}

.teamProgressGraphWrapper {
    width: 350px;
}

.teamProgressGraphSubtitle {
    font: 18px "Poppins", sans-serif;
    text-transform: capitalize;
    color: rgba(33, 34, 43, 0.66);
}

.teamProgressGraphDate {
    font: bold 1rem "Poppins", sans-serif;
    text-transform: uppercase;
    color: var(--restoic-black);
}

.teamProgressGraphPercentage {
    font: 700 28px "Poppins", sans-serif;
    text-align: center;
    color: var(--restoic-black);
    text-transform: uppercase;
}

.teamProgressGraphPercentageSubtitle {
    font: 600 12px "Poppins", sans-serif;
    color: var(--restoic-black);
    text-transform: uppercase;
}

.teamProgressMemberWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 85px;
}

.teamProgressMemberFilterHeader {
    display: flex;
    justify-content: space-between;
}

.teamProgressMemberFilterTitle {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: var(--restoic-black);
}

.teamProgressDummyDiv {
    width: 100px;
}

.teamProgressFilterWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.teamProgressFilterLabel {
    font: 600 18px "Poppins", sans-serif;
    text-transform: uppercase;
    color: #e83a1f;
}

.teamProgressFilterIcon {
    width: 11px;
    margin-left: 5px;
}

.teamProgressMemberStatsWrapper {
    display: flex;
    flex-direction: column;
}
