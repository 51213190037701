.switchBox {
  position: relative;
  float: right;
  top: -40px;
  right: 20px;
  display: flex;
  align-items: center;
}

.athletesHeaderContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: -80px;
}

.athletesHeaderTitle {
  margin-left: 20px;
  font: bold 2rem "Poppins", sans-serif;
  font-size: clamp(1rem, 2.5vw, 2rem);
}

.loadingLogo {
  position: absolute;
  background-image: url("../../../../assets/images/logo.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  -moz-filter: invert(100%);
  animation: enlarge 2s linear infinite;
  -webkit-animation: enlarge 2s linear infinite;
  -moz-animation: enlarge 2s linear infinite;
}

.athletesPrintButtonBox {
  display: flex;
  align-items: center;
  font: 14px normal Poppins, sans-serif;
  text-transform: uppercase;
  margin-left: 40px;
  z-index: 2;
}

.athletesOverviewBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: center;
}

.athletesCircProg {
  position: relative;
  background: #F8F9FB;
  border-radius: 50%;
}

.athletesCircProg::before {
  content: "";
  position: absolute;
  width: 85%;
  height: 85%;
  top: 7.5%;
  left: 7.5%;
  border-radius: 50%;
  background: var(--restoic-grey);
}

.athletesCircProgLabel {
  position: absolute;
  top: 30%;
  left: 30%;
  font: bold 28px "Poppins", sans-serif;
}

.athletesOverviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  min-width: 260px;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 18px 18px rgb(25 31 79 / 13%);
  height: 185px;
}

.athletesOverviewContent {
  display: flex;
  flex-direction: column;
  background: white;
  height: 175px;
  overflow-y: auto;
  overflow-x: hidden;
}

.athletesCircBoxTitle {
  margin: 2px;
  font: bold 1rem "Poppins", sans-serif;
  font-size: clamp(.75rem, 2.5vw, 1rem);
  text-transform: uppercase;
}

.athletesContentTitle {
  margin-top: 3rem;
  font: bold 1.25rem Poppins, sans-serif;
  text-transform: uppercase;
}

.athletesProgBarBox {
  padding: 10px;
  display: flex;
  align-items: center;
}

.athletesProgBar {
  width: 100px;
  height: 10px;
  background: var(--restoic-grey);
  border-radius: 5px;
  transform: skew(-45deg);
}

.athletesProgBarRed {
  width: 0px;
  height: 10px;
  background: var(--restoic-red);
  border-radius: 5px;
}

@media print {
  .athletesContainer {
    overflow: visible !important;
  } 

  .navItemContainer, .noDisplay, .athletesPrintButtonBox {
    display: none;
  }

  .athletesOverviewContent {
    box-shadow: none;
  }

  .athletesProgTableBox {
    break-inside: avoid;
    padding-top: 20px;
  }

  @page {
    size: auto;
    margin: 0 0 0 -2.4cm;
  }
}
