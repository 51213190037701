.selectDateLabel {
    font: bold 24px "Poppins", sans-serif;
    text-transform: capitalize;
    color: var(--restoic-black);
}

.selectDateButtonsWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.modalBtn {
    width: 100%;
}

.selectDateCloseButton, .selectDateSubmitButton {
    font: bold 18px "Poppins", sans-serif;
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.selectDateCloseButton:hover, .selectDateSubmitButton:hover {
    filter: brightness(1.2);
}

.selectDateCloseButton {
    background-color: var(--restoic-black);
    margin-right: 30px;
}

.selectDateSubmitButton {
    background-color: var(--restoic-red);
    margin-left: 30px;
}

.logo {
    background-image: url("../../../assets/images/red_background_logo.png");
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    margin-bottom: 24px;
}

.modalTitle {
    font: 600 28px "Poppins", sans-serif;
    color: var(--restoic-black);
    margin-bottom: 14px;
}

.modalBody {
    font: 22px "Poppins", sans-serif;
    color: var(--restoic-black);
    margin-bottom: 35px;
}
